












































































import {Component, Vue, Prop } from 'vue-property-decorator';
import Navbar from '@/components/common/Navbar.vue';
import Illustration from '@/components/common/Illustration.vue';
import Snackbar from '@/components/common/Snackbar.vue'
import GamificationService from '@/services/GamificationService';
import ActionablePopup from '@/components/common/Popup/ActionablePopup.vue'
import DataTable from '@/components/common/Tables/DataTable.vue'
import TextButton from '@/components/common/Buttons/TextButton.vue'
import {INormList} from '@/interfaces/attendancenorm';
import {ILink, ITableHeader} from '@/interfaces/common'
import { VLayout } from 'vuetify/lib';
import { oldPortalUrl } from '@/envConfig';
import AttendanceNormService from '@/services/AttendanceNormService';

@Component({
    components: {
        Illustration,
        Navbar,
        Snackbar,
        ActionablePopup,
        DataTable,
        TextButton
    }
})
export default class Create extends Vue{
    // for illustration
    private description: string = "No Norms Found" 
    private subdescription: string = "Please create a Norm to begin with"
    private valid:boolean = false   
 
    private timeout !: NodeJS.Timeout
    private message: string = ''
    private snackbar: boolean = false
    private snackbarColor: string = 'success'
    private showGamesList: boolean = true
    //flags for create or edit check
    private createNorm: boolean = true
    private menuItemsActive: Array<ILink> = []
    private selectedGameId: number = 0
    private normSearch: string = ""
    private norms: Array<INormList> = []
    private totalNorm: number = 0
    private pages: number = 0
    private page: number = 1

    private pageLoading: boolean = false
    private selectedActionMenu: number = -1
    private confirmationPopup: boolean = false
    private confirmationPopupTitle: string = ""
    private confirmationPopupSubtitle: string = ""
    private confirmationPopupText: string = ""
    private cancelBtnText: string = ""
    private confirmBtnText: string = ""
    private tableHeaders: Array<ITableHeader> = [
        {
            text: 'Policy Name',
            value: 'name',
            sortable: true,
            default: ''
        },
        {
            text: 'Status',
            value: 'status',
            sortable: false,
            default: ''
        },
        
        {
            text: 'Actions',
            value: 'action',
            sortable: false,
            default: ''
        }
    ]
    private inactiveNorms: boolean = false

    get formValidate(): Vue &{validate: () => boolean}{
        return this.$refs.form as Vue & {validate: () => boolean}
    }
    get formReset(): Vue &{reset: () => void}{
        return this.$refs.form as Vue & {reset: () => void}
    }



    public showCommunicationError(err : any) {    
        this.snackbarColor = "red";
        this.snackbar = true;
        this.message = window.navigator.onLine ? `Some Error occured` : `Cannot reach server at the moment`;
    }

    public menuActionActive(game: INormList, commandIndex: number){
        this.selectedGameId = game.id;           
        this.selectedActionMenu = commandIndex;
        switch (this.menuItemsActive[commandIndex].Text) {
            case "DEACTIVATE": 
                    this.confirmationPopupTitle = "Confirm Deactivation";
                    this.confirmationPopupSubtitle = "Are you sure you wish to deactivate the game?";
                    this.confirmationPopupText = "";
                    this.cancelBtnText = "Cancel";
                    this.confirmBtnText = "Confirm";
                    this.confirmationPopup = true;
                break;
            default: console.log("Unhandled menuAction")
        }
    }
    public viewGame(value: INormList): void{
        this.createNorm = false;        
    }

    public async refreshGamesList(){
        this.inactiveNorms ? await this.getInActiveNorm(this.normSearch, 1) : await this.getNorms(this.normSearch, 1);
        
    }
    public openCreateTab(value: string): void{
     let createGameURL : string= `${oldPortalUrl}FieldAssistPOC/AttendanceNorm/Create`;
        window.open(createGameURL, '_blank');
    }
    public closeSnackbar(value: boolean): void{
        this.snackbar = value;
    }

    public async getNorms(searchTerm: string, value: number): Promise<boolean>{
        this.pageLoading = true;
        return new Promise<boolean>(res => {
            AttendanceNormService.GetActiveNorm(this.normSearch, value).then((response) => {
                this.norms = response.data.games;
                this.pages = response.data.pages;
                this.totalNorm = response.data.total;
                this.pageLoading = false;
                if(this.norms.length > 0){
                    this.showGamesList = true;
                }else{
                    this.showGamesList = false;
                    this.description = this.normSearch.length == 0 ? "No Norms Found" : "No matching result found" ;
                    this.subdescription = this.normSearch.length == 0 ? "Please create a Norm to begin with" : "Make sure you typed correctly or try finding in inactive norms.";
                }
                res(true);
            }).catch((error) => {
                this.showCommunicationError(error);
                console.log(error);
                this.pageLoading = false;
            })
        })
    }
    public changePage(value: number): void{
        this.page = value;
        this.inactiveNorms ? this.getInActiveNorm(this.normSearch, value) : this.getNorms(this.normSearch, value);
    }
    

    public confirmAction(value: any):void{
            AttendanceNormService.deactivateNorm(value).then((res) => {
                this.snackbarColor = "red";
                this.message = "Norm deactivated and moved to inactive list";
                this.actionablePopup();
                this.refreshGamesList();
            }).catch((err) => {
                this.showCommunicationError(err);
                console.log(err);
            })
        
    } 
    public actionablePopup(){
        this.snackbar = true;
        this.confirmationPopup = false;
        this.showGamesList = true;
    } 
    private async GetMenuActions(): Promise<void> {
            this.menuItemsActive = [
                {
                    Text: "DEACTIVATE",
                    Color: "red"
                }
                ];
    }

    public async getInActiveNorm(searchTerm: string, page: number): Promise<boolean>{
        this.pageLoading = true;
        return new Promise<boolean>(res => {
            AttendanceNormService.GetInActiveNorm(searchTerm, page).then((response) => {
                this.norms = response.data.games;
                this.pages = response.data.pages;
                this.totalNorm = response.data.total;
                this.pageLoading = false;
                res(true);
            }).catch((error) => {
                this.showCommunicationError(error);
                console.log(error);
                this.pageLoading = false;
            })
        })
    }
    public switchGames(){
        if(this.inactiveNorms){
            this.tableHeaders = [
                 {
            text: 'Policy Name',
            value: 'name',
            sortable: true,
            default: ''
        },
        {
            text: 'Status',
            value: 'status',
            sortable: false,
            default: ''
        },
        {
            text: 'Actions',
            value: 'action',
            sortable: false,
            default: ''
        }
            ];
            this.getInActiveNorm(this.normSearch, 1);
        }
        else{
            this.tableHeaders = [
                 {
            text: 'Policy Name',
            value: 'name',
            sortable: true,
            default: ''
        },
        {
            text: 'Status',
            value: 'status',
            sortable: false,
            default: ''
        },
        {
            text: 'Actions',
            value: 'action',
            sortable: false,
            default: ''
        }
    ];
            this.getNorms(this.normSearch, 1);
        }
    }
    async created(){
        this.pageLoading = true;
        this.getNorms(this.normSearch, 1).then(_ => _);
        this.GetMenuActions().then(_ => _);
    }
}
